import { RoleGuardGuard } from './services/role-guard.guard';
import { EmailCheckComponent } from './auth/email-check/email-check.component';
import { NgModule } from '@angular/core';
import { PerformancesectionComponent } from './rcmain/performancesection/performancesection.component';
import { Rc3hisorysectionComponent } from './rcmain/rc3hisorysection/rc3hisorysection.component';
import { Rc3superadminComponent } from './rc3superadmin/rc3superadmin.component';
import { RcmainComponent } from './rcmain/rcmain.component';
import { ReviewbuilderadminComponent } from './rc3superadmin/reviewbuilderadmin/reviewbuilderadmin.component';
import { ReviewsectionComponent } from './rcmain/reviewsection/reviewsection/reviewsection.component';
import { Routes, RouterModule } from '@angular/router';
import { UserroleGuard } from './services/userrole.guard';
import { ErrorpageComponent } from './auth/email-check/errorpage/errorpage.component';
import { RclogoutComponent } from './auth/rclogout/rclogout.component';
import { GoalsectionComponent } from './rcmain/goalsection/goalsection.component';
import { TalentmanagementsectionComponent } from './rcmain/talentmanagementsection/talentmanagementsection.component';
import { PipSectionComponent } from './rcmain/pip-section/pip-section.component';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

const routes: Routes = [
  {path: '', pathMatch: 'full',redirectTo: 'login/rc' },
  {path:'login/rc',component:EmailCheckComponent},
  {path:'login/:id',component:EmailCheckComponent},
  {path:'error',component:ErrorpageComponent},
  {path:'rclogout',component:RclogoutComponent},
  {path:'access-denied',component:AccessDeniedComponent},


  {
      path: '', component: RcmainComponent , canActivate: [UserroleGuard],  children: [
        { path: 'reviewrc3',canActivateChild:[RoleGuardGuard] ,component: ReviewsectionComponent ,children:[
          { path: 'reviewsetting', loadChildren: () => import('./rvcloud/contents/reviewsetting/reviewsetting.module').then(m => m.ReviewsettingModule) },
          { path: 'rc3flowboard', loadChildren: () => import('./rcmain/rc3flowboard/rc3flowboard.module').then(m => m.Rc3flowboardModule) },
          { path: 'rc3reviewbuilder', loadChildren: () => import('./rcmain/rc3reviewbuilder/rc3reviewbuilder.module').then(m => m.Rc3reviewbuilderModule) },

        ] },
        { path: 'performancerc3', component: PerformancesectionComponent ,children:[
          { path: 'performancemanagement', loadChildren: () => import('./rvcloud/contents/performancemanagement/performancemanagement.module').then(m => m.PerformancemanagementModule) }, 
          { path: 'reviewMain', loadChildren: () => import('./rvcloud/contents/performancemanagement/rc3review/rcmainreview/rcmainreview.module').then(m => m.RcmainreviewModule) },
          { path: 'pip-manager', loadChildren: () => import('./rvcloud/contents/pip/pip.module').then(m => m.PipModule) },

          // { path: 'pip-manager', loadChildren: () => import('./rvcloud/contents/goal-management/goalmanager/goalmanager/goalmanager.module').then(m => m.GoalmanagerModule) },

        ] },
        {path:'history',component:Rc3hisorysectionComponent, children:[
          { path: 'rc3history', loadChildren: () => import('./rcmain/rc3hisorysection/rc3history/rc3history.module').then(m => m.Rc3historyModule) },

        ]},
        {path:'rc-goal',component:GoalsectionComponent, children:[
        { path: 'goal-setting', loadChildren: () => import('./rvcloud/contents/goal-management/goal-management.module').then(m => m.GoalManagementModule) },
        { path: 'goal-manager', loadChildren: () => import('./rvcloud/contents/goal-management/goalmanager/goalmanager/goalmanager.module').then(m => m.GoalmanagerModule) },
 
      ]},
      {path:'talentManagmentrc',component:TalentmanagementsectionComponent, children:[
        { path: 'talentmanagement', loadChildren: () => import('./rvcloud/contents/talentmanagement/talentmanagement.module').then(m => m.TalentmanagementModule) },
        { path: 'nineboxgrid', loadChildren: () => import('./rvcloud/contents/talentmanagement/nineboxgrid/nineboxgrid.module').then(m => m.NineboxgridModule) },
        { path: 'idp-manager', loadChildren: () => import('./rvcloud/contents/pip/pip.module').then(m => m.PipModule) },
        { path: 'learningPlan', loadChildren: () => import('./rvcloud/contents/talentmanagement/leaning-plan/leaning-plan.module').then(m => m.LeaningPlanModule) }
      ]},
    ],
    

    },
    {
      path: '', component: Rc3superadminComponent ,  children: [
      { path: 'reviewadminsection', component: ReviewbuilderadminComponent ,children:[
        { path: 'adminreviewbuildersection', loadChildren: () => import('./rc3superadmin/reviewbuilderadmin/adminreviewbuildersection/adminreviewbuildersection.module').then(m => m.AdminreviewbuildersectionModule) },
      ]}
      ]
    },
        
     
  

  
  
  // { path: '**', pathMatch: 'full', component:NotFoundComponent }

];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
