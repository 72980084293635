import { EmployeeId } from './../models/learning-plan-id';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  id:EmployeeId;
  ROUTE:string='';
  url:String=''

  languages:any[]=[
    { "id": "English", "name": "English" },
    { "id": "Spanish", "name": "Spanish" },
    // { "id": "Portuguese", "name": "Portuguese" },
    // { "id": "Italian", "name": "Italian" },
    // { "id": "French", "name": "French" },
    // { "id": "Chinese", "name": "Chinese" },
    // { "id": "Dutch", "name": "Dutch" },
    // { "id": "Japanese", "name": "Japanese" },
    // { "id": "Canadian", "name": "Canadian" },
    // { "id": "French-Canadian", "name": "French-Canadian" }
  ]
  
  month:any[]=[
    {
      "id":1,
      "abbreviation": "Jan",
      "name": "January"
    },
    {
      "id":2,
      "abbreviation": "Feb",
      "name": "February"
    },
    {
      "id":3,
      "abbreviation": "Mar",
      "name": "March"
    },
    {
      "id":4,
      "abbreviation": "Apr",
      "name": "April"
    },
    {
      "id":5,
      "abbreviation": "May",
      "name": "May"
    },
    {
      "id":6,
      "abbreviation": "Jun",
      "name": "June"
    },
    {
      "id":7,
      "abbreviation": "Jul",
      "name": "July"
    },
    {
      "id":8,
      "abbreviation": "Aug",
      "name": "August"
    },
    {
      "id":9,
      "abbreviation": "Sep",
      "name": "September"
    },
    {
      "id":10,
      "abbreviation": "Oct",
      "name": "October"
    },
    {
      "id":11,
      "abbreviation": "Nov",
      "name": "November"
    },
    {
      "id":12,
      "abbreviation": "Dec",
      "name": "December"
    }
  ]

  constructor(private router:Router) { 

    

  }

  

  getLanguage()
  {
    return this.languages
  }

  saveId(id:EmployeeId){
    this.id=id
    
  }


getId()
{
  return this.id
}

  getRoute()
  {
    this.url=this.router.routerState.snapshot.url

    if(this.url.includes('pip'))
      {
        this.ROUTE='/performancerc3/pip-manager';
      }
      else if(this.url.includes('idp'))
      {
        this.ROUTE='/talentManagmentrc/idp-manager'
      }
 
    
      return this.ROUTE
  }


  getRouteLearningPlan()
  {
    this.url=this.router.routerState.snapshot.url
    

    if(this.url.includes('talentManagmentrc'))
      {
        this.ROUTE='/talentManagmentrc/learningPlan/lp';
      }
      else if(this.url.includes('performancerc3/reviewMain'))
      {
        this.ROUTE='/performancerc3/reviewMain/lp'
      }
 
    
      return this.ROUTE
  }




  routeType(url)
  {
    if(url.includes('pip'))
    {
      return 'PIP';
    }
    if(url.includes('idp'))
    {
      return 'IDP';
    }


  }


}
