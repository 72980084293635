import { ApiHandling } from './services/interceptors/api-handling';
import { AngularmaterialModule } from './shared/shared/angularmaterial/angularmaterial.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './services/auth.guard';
import { AuthInterceptorService } from './services/interceptors/auth-interceptor.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CanDeactivateGuard } from './shared/components/candeactiveguard.guard';
import { DatePipe } from '@angular/common';
import { EmailCheckComponent } from './auth/email-check/email-check.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { LoadingInterceptorService } from './services/loader/loading-interceptor.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { PerformancesectionComponent } from './rcmain/performancesection/performancesection.component';
import { Rc3hisorysectionComponent } from './rcmain/rc3hisorysection/rc3hisorysection.component';
import { Rc3superadminComponent } from './rc3superadmin/rc3superadmin.component';
import { RcmainComponent } from './rcmain/rcmain.component';
import { ReviewbuilderadminComponent } from './rc3superadmin/reviewbuilderadmin/reviewbuilderadmin.component';
import { ReviewsectionComponent } from './rcmain/reviewsection/reviewsection/reviewsection.component';
import { SidemenuComponent } from './rvcloud/sidemenu/sidemenu.component';
import { StoreModule } from '@ngrx/store';
import { ToastrModule } from 'ngx-toastr';
import { UserroleGuard } from './services/userrole.guard';
import { reducers } from './shared/store';
import { IConfig } from 'ngx-mask/lib/ngx-mask.config';
import { ErrorpageComponent } from './auth/email-check/errorpage/errorpage.component';
import { SharedModule } from './shared/shared/shared.module';
import { SignoutpopupComponent } from './rvcloud/signoutpopup/signoutpopup.component';
import { RclogoutComponent } from './auth/rclogout/rclogout.component';
import { ErrorMessageDialogComponent } from './shared/components/error-message-dialog/error-message-dialog.component';
import { GoalsectionComponent } from './rcmain/goalsection/goalsection.component';
import { TalentmanagementsectionComponent } from './rcmain/talentmanagementsection/talentmanagementsection.component';
import { PipSectionComponent } from './rcmain/pip-section/pip-section.component';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';


const maskConfig: Partial<IConfig> = {
    validation: false,
};






@NgModule({
    declarations: [
        AppComponent,
        EmailCheckComponent,
        PerformancesectionComponent,
        Rc3hisorysectionComponent,
        Rc3superadminComponent,
        RcmainComponent,
        ReviewbuilderadminComponent,
        ReviewsectionComponent,
        SidemenuComponent,
        ErrorpageComponent,
        SignoutpopupComponent,
        RclogoutComponent,
        ErrorMessageDialogComponent,
        GoalsectionComponent,
        TalentmanagementsectionComponent,
        PipSectionComponent,
        AccessDeniedComponent,
        NotFoundComponent,
        
        
  
        
       
        
        
      
      
    ],
    providers: [
        MatDatepickerModule,
        CanDeactivateGuard,
        BnNgIdleService,
        AuthGuard,
        UserroleGuard,
        {
            provide: MAT_DIALOG_DATA,
            useValue: []
        },
        DatePipe,
      
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptorService,
            multi: true
        },
    
    ],
    bootstrap: [AppComponent],
    imports: [  
        AngularmaterialModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        LayoutModule,
        SharedModule,
        NgxMaskModule.forRoot(maskConfig),  
        NoopAnimationsModule,
        ReactiveFormsModule,
        StoreModule.forFeature('user', reducers),
        StoreModule.forRoot({}),
        ToastrModule.forRoot(
            {
                positionClass:'toast-top-center'
            }
        ),
        

    ]



})
export class AppModule { }



