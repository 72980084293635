<div class="topbar">
<header  class="header">
    <div class="row"  style="height:55px;">
      <nav class="navbar navbar-expand-lg navbar-light bg-light" >
        <a class="navbar-brand headerspace" >
          <img src=".../../../../assets/img/RCLogo.png"  class="headerlogo">
          <!-- <img src=".../../../../assets/img/rclogo.png"  class="headerlogo"> -->
        </a>
      
        <div class="collapse navbar-collapse blacknav" id="navbarSupportedContent">
          <div class="btn-box1">
      


            <ul class="navbar-nav nav-external1"  >
              <ng-container  *ngFor="let topmenu of reviewTopSection.top_menu" >
                 <li class="nav-item"  *ngIf="isAccessable(topmenu)"
                  >
                  <a class="nav-link1" 
                  [class.active]="isActiveUrl(topmenu.name)"
                  (click)="onClickChangeNavigation(topmenu.name)"
                  >
                    <span>{{topmenu.name}}</span></a>
                </li>
              </ng-container>
                </ul>

           

            
            </div>
     
            <div class="header-profile-top">
              <div class="modal-main-div">
              <img class="nine-dots-hover" [matMenuTriggerFor]="appMenu" src="../../../assets/img/side-menu/Ninedots.png" alt="">
   
   
              <div class="texts-navv2" (click)="SignoutPopup()">
                <img src="{{user_Data?.profile_pic}}" alt="" class="modal-image-look d-none d-sm-inline-block"
                  *ngIf="user_Data?.profile_pic" height="30" />
      
                <span class="modal-nav-text-look d-none d-sm-inline-block ng-star-inserted avatar-line-height"
                  *ngIf="user_Data?.profile_pic == '' || user_Data?.profile_pic == null">{{user_Data.first_name ?.charAt(0)?.toUpperCase()}}</span>
      
                <div class="newText1">
                  <span class="d-none d-sm-inline-block">{{user_Data?.full_name}}{{" "}}</span>
                  <span class="newText1-span">
                    <img src="../../../assets/img/side-menu/Down.png" alt="" class="dowm-modal-icon" />
                  </span>
                </div>
              </div>

            </div>
            <mat-menu #appMenu="matMenu" xPosition="after" yPosition="below" class="app-menu">
              <div class="my-apps-wrap">
                <h6>My Apps</h6>
                <ng-container *ngFor="let myapp of myapps;">
                  <button mat-menu-item>
                    <a href="{{myapp?.url}}" class="d-block">
                      <img src="{{myapp?.logo}}" width="100" height="20" />
                    </a>
                  </button>
                </ng-container>
              </div>
              <hr>
              <div class="home-app-wrap">
                <h6>Home Page</h6>
                <button mat-menu-item>
                  <a href="{{trainery_url}}" class="d-block">
                    <img src="../../../assets/img/side-menu/trainerylogo.png" width="110" height="20" />
                  </a>
                </button>
              </div>
            </mat-menu>

       

            
          </div>
        </div>
      </nav>
        </div>
  
      </header>
    <!-- </section> -->
  
     
    <!-- Side menu ends (mouseover)='over()' (mouseout)='out()'-->
    <div class="left-nav" >

      <app-sidemenu ></app-sidemenu>
  
    </div>
  <!-- side small -->
  
  
  
    <!-- Side menu ends -->
    <!-- <div class="content-section"> -->
      <div class="right-data"  [ngStyle]="{'left.px': divStyle}">
      <!-- <app-sidemenu class="side"></app-sidemenu> -->

      
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </div>